<template>
<!-- 가이드워드별 원인 목록 -->
  <c-table
    ref="table"
    title="LBL0001246"
    :columns="grid.columns"
    :gridHeight="grid.height"
    :data="gridData"
    :filtering="false"
    :columnSetting="false"
    :hideBottom="false"
    :usePaging="false"
    :editable="editable&&!disabled"
    selection="multiple"
    rowKey="ramVariableGuidewordId"
  >
    <!-- 버튼 영역 -->
    <template slot="table-button">
      <q-btn-group outline >
        <!-- 추가 -->
        <c-btn v-if="editable&&!disabled" :showLoading="false" label="LBLADD" icon="add" @btnClicked="addCause" />
        <!-- 제외 -->
        <c-btn v-if="editable&&!disabled" :showLoading="false" label="LBLEXCEPT" icon="remove" @btnClicked="deleteCause" />
      </q-btn-group>
    </template>
  </c-table>
</template>

<script>
export default {
  name: 'hazop-deviation-posible-cause',
  props: {
    variable: {
      type: Object,
      default: function() {
        return {
          ramVariableId: '',  // 변수 번호
          variableGuidewords: [], // 사용 가이드워드 목록
          deleteVariableGuidewords: [], // [삭제] 사용 가이드워드 목록
        }
      },
    },
    ramVariableGuidewordCd: {
      type: String,
      default: function() {
        return ''
      }
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false
      }
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'causeName',
            field: 'causeName',
            // 가능한원인
            label: 'LBL0001247',
            align: 'left',
            type: 'textarea',
            sortable: false,
          },
        ],
        data: [],
        height: '500px'
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridData() {
      return this.$_.filter(this.variable.variableGuidewords, { ramVariableGuidewordCd: this.ramVariableGuidewordCd });
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
    addCause() {
      let date = new Date();
      if (!this.variable.variableGuidewords) {
        this.variable.variableGuidewords = [];
      }
      this.variable.variableGuidewords.push({
        ramVariableGuidewordId: String(date.getTime()),
        ramVariableGuidewordCd: this.ramVariableGuidewordCd,
        causeName: '',
        editFlag: 'C'
      })
    },
    deleteCause() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.variable.deleteVariableGuidewords) {
          this.variable.deleteVariableGuidewords = [];
        }
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.variable.deleteVariableGuidewords, { ramVariableGuidewordId: item.ramVariableGuidewordId }) === -1 
            && item.editFlag !== 'C') {
            this.variable.deleteVariableGuidewords.push(item)
          }
          this.variable.variableGuidewords = this.$_.reject(this.variable.variableGuidewords, { ramVariableGuidewordId: item.ramVariableGuidewordId })
        })
      }
    },
  }
};
</script>
